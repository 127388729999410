<template>
  <div>
    <director_app_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'คัดเลือก ตำแหน่ง ผู้อำนวยการ ครั้งที่ : ' +
            period_sedirectors.period_sedirector_times +
            ' ปี : ' +
            period_sedirectors.period_sedirector_year
        "
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card class="pa-1">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td class="text-center">
                        <h4 class="font-weight-bold" color="info">
                          ประเมิน ภาค ก :
                          <v-btn
                            @click="sedirector_appQueryAll()"
                            fab
                            color="success"
                            class="font-weight-bold"
                            dark
                            small
                            >{{ sedirector_appCount.sumAll }}
                          </v-btn>
                          คน
                        </h4>
                      </td>
                      <td class="text-center">
                        <h4 class="font-weight-bold" color="info">
                          รายงานผล ภาค ก :
                          <v-btn
                            @click="sedirector_appQueryAll()"
                            fab
                            color="success"
                            class="font-weight-bold"
                            dark
                            small
                            >{{ sedirector_appCount.sedirector_app_pvcscore_A }}
                          </v-btn>
                          คน
                        </h4>
                      </td>
                      <td class="text-center">
                        <v-btn                        
                          rounded
                          color="info"
                          :href="
                            '#/admin/print_director_app_score_a/?times=' +
                              period_sedirectors.period_sedirector_times +
                              '&years=' +
                              period_sedirectors.period_sedirector_year
                          "
                          target="_blank"
                          ><v-icon>mdi-printer</v-icon>ส่งออกข้อมูลผู้สมัคร
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center">
                        <h4 class="font-weight-bold" color="info">
                           ขอยกเลิกแนบไฟล์คะแนน :
                          <v-btn
                            @click="SearchCCpvcdoc()"
                            fab
                            color="red"
                            class="font-weight-bold"
                            dark
                            small
                            >{{
                              sedirector_appCount.sedirector_app_pvcscore_AB_CC
                            }}
                          </v-btn>
                          คน
                        </h4>
                      </td>
                      <td class="text-center">
                        <h4 class="font-weight-bold" color="info">
                           ไม่ได้บันทึกรายการ :
                          <v-btn
                            @click="Searchtemporary()"
                            fab
                            color="warning"
                            class="font-weight-bold"
                            dark
                            small
                            >{{ sedirector_appCount.dotsave }}
                          </v-btn>
                          คน
                        </h4>
                      </td>
                      <td class="text-center">
                        <v-btn 
                        @click="noticeApproveIpa()"                        
                          rounded
                          color="success"                         
                          ><v-icon>mdi-checkbox-marked-circle-outline</v-icon>ประกาศผล ภาค ก.
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table></v-card
              >
            </v-col>
          </v-row>
        </v-card>
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
          :item-class="row_classes"
        >

        <template v-slot:item.actions="{ item }">
            <div v-if="item.sedirector_app_id === editedItem.sedirector_app_id">
              <v-icon color="red" class="mr-3" @click="close">
                mdi-window-close
              </v-icon>
              <v-icon color="green" @click="save()">
                mdi-content-save
              </v-icon>
            </div>
            <div v-else>
              <v-icon color="green" class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon color="red" @click="deleteItem(item.sedirector_app_idref)">
                mdi-delete
              </v-icon>
            </div>
          </template>

          
        <template v-slot:item.sedirector_app_pvcscore_A_ipa="{ item }">
            <div>
              <v-icon
                color="green"
                v-if="item.sedirector_app_pvcscore_A_ipa === 'notice'"
                >mdi-cast</v-icon
              >
              <v-icon color="dark" v-else>mdi-cast-off</v-icon>
            </div>
          </template>


          <template v-slot:item.sedirector_app_pvcscore_A="{ item }">
            <v-chip
              color="success"
              dark
              v-if="item.sedirector_app_pvcscore_A >= 60"
            >
              {{ item.sedirector_app_pvcscore_A }}
            </v-chip>
            <v-chip
              color="red"
              dark
              v-else-if="item.sedirector_app_pvcscore_A >= 1"
            >
              {{ item.sedirector_app_pvcscore_A }}
            </v-chip>
            <v-chip color="grey" dark v-else>
              -
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_A_file="{ item }">
            <div v-if="item.sedirector_app_pvcscore_A_file">
              <v-btn
                @click="
                  viewsedirector_app_pvcscore_A_file(
                    item.sedirector_app_pvcscore_A_file
                  )
                "
                fab
                small
                color="success"
                dark
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้รายงานผล
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_AB_CC="{ item }">
            <div v-if="item.sedirector_app_pvcscore_AB_CC === 'ACC'">
              <v-btn
                rounded
                @click="cancelpvcScoreA(item.sedirector_app_idref)"
                color="red"
                dark
              >
                <v-icon>mdi-information-outline</v-icon> ขอยกเลิกไฟล์</v-btn
              >
            </div>
            <div v-else-if="item.sedirector_app_pvcscore_AB_CC === 'CF'">
              <v-chip            
                color="success"
                rounded
                >บันทึก</v-chip
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้รายงานผล
              </v-chip>
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model sedirector_app_pvcscore_A_filedialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="sedirector_app_pvcscore_A_filedialog"
          max-width="80%"
        >
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import director_app_bar from "../../../components/admin/director/director_app_bar";

export default {
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      position: "ครู",
      branch_s: [],
      sedirector_app_idref: [],
      valid: true,
      times_select: "1",
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      sedirector_app_time: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      sedirector_apps: [],
      editsedirector_app: {},
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
      {
          text: "Action",
          align: "center",
          value: "sedirector_app_pvcscore_A_ipa"
        },

        { text: "จังหวัด", align: "center", value: "province_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "user_firstname" },
        { text: "ดำเนินการ", align: "center", value: "actions" },

        {
          text: "คะแนน ภาค ก.",
          align: "center",
          value: "sedirector_app_pvcscore_A"
        },

        {
          text: "ไฟล์คะแนน ภาค ก.",
          align: "center",
          value: "sedirector_app_pvcscore_A_file"
        },

        {
          text: "สถานะ",
          align: "center",
          value: "sedirector_app_pvcscore_AB_CC"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      sedirector_appstatus: [],
      sedirector_apps_id_ref: [],
      conditons_transfer_successs: [],
      updatepositions_condition: {},
      man_power_cancel: {},
      addreturn_man_power: {},
      transference_locations: [],
      conditons_transfer_success_del: [],
      man_powers: [],
      userstatus: {},
      updatepositions: {},
      man_powerss: [],
      id_return_man_powers: [],
      colleges: [],
      data_select: [],
      provinces: [],
      regions: [],
      period_sedirectors: [],
      period_sedirector_process: "1",
      updatecomment: {},
      transference_locationTfl: [],
      updatetlMcommittee: {},
      sedirector_appCount: {},
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      pdffiles: "",
      sedirector_app_pvcscore_A_filedialog: false,
      update_sedirector_app:{},
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    await this.period_sedirectorQuery();
    await this.sedirector_appQueryAll();
  },
  methods: {

    async noticeApproveIpa() {
      Swal.fire({
        title: "ประกาศรายชื่อผู้ผ่านคุณสมบัติ ภาค ก. ?",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ประกาศ",
        cancelButtonText: "ยกเลิก",
        denyButtonText: `ยกเลิกประกาศ`
      }).then(async result => {
        if (result.isConfirmed) {
          this.update_sedirector_app.ApiKey = this.ApiKey;
          this.update_sedirector_app.sedirector_app_ptype = 3;
          this.update_sedirector_app.sedirector_app_year = this.period_sedirectors.period_sedirector_year;
          this.update_sedirector_app.sedirector_app_time = this.period_sedirectors.period_sedirector_times;
          this.update_sedirector_app.sedirector_app_pvcscore_A_ipa = "notice";
          let resultupdate = await this.$http.post(
            "sedirector_app.update.admin.dir.php",
            this.update_sedirector_app
          );
          
          if (resultupdate.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else if (result.isDenied) {
          this.update_sedirector_app.ApiKey = this.ApiKey;
          this.update_sedirector_app.sedirector_app_year = this.period_sedirectors.period_sedirector_year;
          this.update_sedirector_app.sedirector_app_time = this.period_sedirectors.period_sedirector_times;
          this.update_sedirector_app.sedirector_app_pvcscore_A_ipa = "";
          let resultupdate = await this.$http.post(
            "sedirector_app.update.admin.php",
            this.update_sedirector_app
          );
          
          if (resultupdate.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },


    async cancelpvcScoreA(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let text = this.sedirector_apps_id_ref.fristnames;
      Swal.fire({
        title: "คุณต้องการขอยกเลิกรายงานผลภาค ก ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ขอยกเลิก",
        cancelButtonText: "ปิด"
      }).then(async result => {
        if (result.isConfirmed) {
          let result;
          this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
          result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.sedirector_apps_id_ref.sedirector_app_pvcscore_A_file
          });
          this.sedirector_apps_id_ref.sedirector_app_pvcscore_A = "";
          this.sedirector_apps_id_ref.sedirector_app_pvcscore_A_file = "";
          this.sedirector_apps_id_ref.sedirector_app_pvcscore_AB_CC = "";

          result = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps_id_ref
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async viewsedirector_app_pvcscore_A_file(sedirector_app_pvcscore_A_file) {
      this.pdffiles = sedirector_app_pvcscore_A_file;
      this.sedirector_app_pvcscore_A_filedialog = true;
    },

    async changeStatus(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        id_ref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let text =
        this.sedirector_apps_id_ref.frist_name +
        this.sedirector_apps_id_ref.last_name;
      
      Swal.fire({
        title: "คุณต้องการเปลี่ยนสถานะ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "เปลี่ยนสถานะ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
          this.sedirector_apps_id_ref.sedirector_app_status = "save";
          let resultupdate = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps_id_ref
          );
       
          await this.sedirector_appQueryAll();
        }
      });
    },

    async Searchtemporary() {
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year
        })
        .finally(() => (this.loading = false));
      let data = result.data;
      let obj = {};
      let arr = [];
      data.forEach(value => {
        if (value.sedirector_app_pvcscore_AB_CC != "CF") {
          obj["province_name"] = value.province_name;
          obj["sedirector_app_idcard"] = value.sedirector_app_idcard;
          obj["sedirector_app_status"] = value.sedirector_app_status;
          obj["fristnames"] = value.fristnames;
          obj["user_firstname"] = value.user_firstname;
          obj["id_position"] = value.id_position;
          obj["sedirector_app_time"] = value.sedirector_app_time;
          obj["sedirector_app_agework"] = value.sedirector_app_agework;
          obj["sedirector_app_position"] = value.sedirector_app_position;
          obj["sedirector_age_position"] = value.sedirector_age_position;
          obj["sedirector_age_position_work5"] =
            value.sedirector_age_position_work5;
          obj["sedirector_app_discipline"] = value.sedirector_app_discipline;
          arr.push({
            province_name: obj["province_name"],
            sedirector_app_idcard: obj["sedirector_app_idcard"],
            sedirector_app_status: obj["sedirector_app_status"],
            fristnames: obj["fristnames"],
            user_firstname: obj["user_firstname"],
            id_position: obj["id_position"],
            sedirector_app_time: obj["sedirector_app_time"],
            sedirector_app_agework: obj["sedirector_app_agework"],
            sedirector_app_position: obj["sedirector_app_position"],
            sedirector_age_position: obj["sedirector_age_position"],
            sedirector_age_position_work5: obj["sedirector_age_position_work5"],
            sedirector_app_discipline: obj["sedirector_app_discipline"]
          });
        }
      });
      this.sedirector_apps = arr;
    },

    async SearchCCpvcdoc() {
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype:3,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year,
          sedirector_app_pvcscore_AB_CC: "ACC"
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
    },

    async SearchCCdoc() {
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year,
          type_move: "nm",
          ccDocumentFile: "cc"
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
    },

    async actionReceipts(sedirector_app_idref) {
      this.updatetlMcommittee.ApiKey = this.ApiKey;
      this.updatetlMcommittee.sedirector_app_idref = sedirector_app_idref;
      this.updatetlMcommittee.tp14receipt = "success";
      let result = await this.$http.post(
        "sedirector_app.update.receipt.php",
        this.updatetlMcommittee
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.sedirector_appQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    async actionReceiptUdate(sedirector_app_idref) {
      this.updatetlMcommittee.ApiKey = this.ApiKey;
      this.updatetlMcommittee.sedirector_app_idref = sedirector_app_idref;
      this.updatetlMcommittee.tp14receipt = "";
      let result = await this.$http.post(
        "sedirector_app.update.receipt.php",
        this.updatetlMcommittee
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.sedirector_appQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    row_classes(item) {
      if (item.id_position_new > 0) {
        return "info";
      } else if (item.ccDocumentFile == "cc") {
        return "yellow";
      } else if (item.ccDocumentFilePVC == "cc") {
        return "yellow";
      }
    },

    async CancelFilePDF(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.CancelFilePDFDrdialog = true;
    },

    async CandelFilePDFSubmit() {
      if (this.$refs.CancelFilePDFDrdialogform.validate()) {
        let result;
        this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HrvecRegisDir/" +
            this.sedirector_apps_id_ref.sedirector_app_file
        });
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HrvecRegisDir/" +
            this.sedirector_apps_id_ref.sedirector_app_file_A
        });
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HrvecRegisDir/" +
            this.sedirector_apps_id_ref.sedirector_app_file_B
        });

        result = await this.$http.post(
          "sedirector_app.update.admin.php",
          this.sedirector_apps_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.CancelFilePDFDrdialog = false;
      }
    },
    async deletetpteach(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        id_ref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.DeleteTpteachDrdialog = true;
    },
    async DeleteSubmit() {
      if (this.$refs.DeleteTpteachDrdialogform.validate()) {
        this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "sedirector_app.delete.php",
          this.sedirector_apps_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.DeleteTpteachDrdialog = false;
      }
    },
    async period_sedirectorQuery() {
      let result_period_sedirector;
      result_period_sedirector = await this.$http.post(
        "period_sedirector.php",
        {
          ApiKey: this.ApiKey,
          period_sedirector_process: "1",
          period_sedirector_id: "301"
        }
      );
      this.period_sedirectors = result_period_sedirector.data;
    },
    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype: 3,
          sedirector_app_time: this.times_select,
          sedirector_app_year: this.years_select,
          type_move: "nm"
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
    },

    async tp_comment_dr_stbPosition(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        id_ref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.comment_stb_Drdialog = true;
    },
    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype:3,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year,
          sedirector_app_pvc_approve: "pass"
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;



      let data = this.sedirector_apps;
      let sum = 0;
      let sedirector_app_pvcscore_A = 0;
      let sedirector_app_pvcscore_AB_CC = 0;
      let dotsave = 0;
      data.forEach(value => {
        if (value.sedirector_app_pvc_approve == "pass") {
          sum += 1;
        }
        if (value.sedirector_app_pvcscore_A_file) {
          sedirector_app_pvcscore_A += 1;
        } else {
          dotsave += 1;
        }

        if (value.sedirector_app_pvcscore_AB_CC == "ACC") {
          sedirector_app_pvcscore_AB_CC += 1;
        }
      });
      this.sedirector_appCount.sumAll = sum;
      this.sedirector_appCount.sedirector_app_pvcscore_A = sedirector_app_pvcscore_A;
      this.sedirector_appCount.sedirector_app_pvcscore_AB_CC = sedirector_app_pvcscore_AB_CC;
      this.sedirector_appCount.dotsave = dotsave;
    },

    async updatecommentSubmit() {
      if (this.$refs.updatecommentform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.sedirector_app_idref = this.sedirector_apps_id_ref.sedirector_app_idref;
        this.updatecomment.comment_dr_c = this.sedirector_apps_id_ref.comment_dr_c;
        this.updatecomment.id_branch = this.sedirector_apps_id_ref.id_branch_tan;
        this.updatecomment.detail_comment = this.sedirector_apps_id_ref.detail_comment;
        this.updatecomment.status_document = this.sedirector_apps_id_ref.status_document;

        let result = await this.$http.post(
          "sedirector_app.update.php",
          this.updatecomment
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.commentDrdialog = false;
      }
    },

    async sedirector_app_fileSubmit() {
      if (this.$refs.sedirector_app_fileform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.sedirector_app_idref = this.sedirector_apps_id_ref.sedirector_app_idref;
        let result = "";
        let uploaded = null;

        if (this.sedirector_app_files != "") {
          let formData = new FormData();
          let filename =
            this.sedirector_apps_id_ref.sedirector_app_idref +
            "" +
            this.time_stamp +
            "" +
            "doc.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_files);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.updatecomment.sedirector_app_file = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        if (this.sedirector_app_files3 != "") {
          let formData = new FormData();
          let filename =
            this.sedirector_apps_id_ref.sedirector_app_idref +
            "" +
            this.time_stamp +
            "" +
            "cp14.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_files3);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.updatecomment.sedirector_app_file_A = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        result = await this.$http.post(
          "sedirector_app.update.php",
          this.updatecomment
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.sedirector_app_filedialog = false;
      }
    },

    async deletePosition(id_ref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let result_man = await this.$http.post("man_power.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idcard: this.sedirector_apps_id_ref.sedirector_app_idcard
      });
      this.man_powerss = result_man.data;
      let result_cts = await this.$http.post("conditons_transfer_success.php", {
        ApiKey: this.ApiKey,
        ctsid_ref: id_ref
      });
      this.conditons_transfer_successs = result_cts.data;
      this.canceldialog = true;
    },

    async cancelSubmit() {
      if (this.$refs.cancelform.validate()) {
        this.man_powerss.ApiKey = this.ApiKey;
        this.man_powerss.status_booking = "";
        this.conditons_transfer_successs.ApiKey = this.ApiKey;
        this.man_power_cancel.ApiKey = this.ApiKey;
        this.man_power_cancel.id_position = this.conditons_transfer_successs.ctsid_postion_old;
        let result_man = await this.$http.post(
          "man_power.update_process.php",
          this.man_powerss
        );
        let result_man_delete = await this.$http.post(
          "man_power.delete.php",
          this.man_power_cancel
        );
        let result_cts = await this.$http.post(
          "conditons_transfer_success.delete.php",
          this.conditons_transfer_successs
        );
        if (
          result_man.data.status == true &&
          result_cts.data.status == true &&
          result_man_delete.data.status == true
        ) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "ยกเลิกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.sedirector_appQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ยกเลิกข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.canceldialog = false;
      }
    },
    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_sedirector_years() {
      let yyyy = this.period_sedirectors.period_sedirector_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { director_app_bar }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
