var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('director_app_bar'),_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{attrs:{"icon":"mdi-clipboard-text","title":'คัดเลือก ตำแหน่ง ผู้อำนวยการ ครั้งที่ : ' +
          _vm.period_sedirectors.period_sedirector_times +
          ' ปี : ' +
          _vm.period_sedirectors.period_sedirector_year}},[_c('v-card',{staticClass:"mb-4 pa-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"pa-1"},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('h4',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" ประเมิน ภาค ก : "),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"fab":"","color":"success","dark":"","small":""},on:{"click":function($event){return _vm.sedirector_appQueryAll()}}},[_vm._v(_vm._s(_vm.sedirector_appCount.sumAll)+" ")]),_vm._v(" คน ")],1)]),_c('td',{staticClass:"text-center"},[_c('h4',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" รายงานผล ภาค ก : "),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"fab":"","color":"success","dark":"","small":""},on:{"click":function($event){return _vm.sedirector_appQueryAll()}}},[_vm._v(_vm._s(_vm.sedirector_appCount.sedirector_app_pvcscore_A)+" ")]),_vm._v(" คน ")],1)]),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"info","href":'#/admin/print_director_app_score_a/?times=' +
                            _vm.period_sedirectors.period_sedirector_times +
                            '&years=' +
                            _vm.period_sedirectors.period_sedirector_year,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v("ส่งออกข้อมูลผู้สมัคร ")],1)],1)]),_c('tr',[_c('td',{staticClass:"text-center"},[_c('h4',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" ขอยกเลิกแนบไฟล์คะแนน : "),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"fab":"","color":"red","dark":"","small":""},on:{"click":function($event){return _vm.SearchCCpvcdoc()}}},[_vm._v(_vm._s(_vm.sedirector_appCount.sedirector_app_pvcscore_AB_CC)+" ")]),_vm._v(" คน ")],1)]),_c('td',{staticClass:"text-center"},[_c('h4',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" ไม่ได้บันทึกรายการ : "),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"fab":"","color":"warning","dark":"","small":""},on:{"click":function($event){return _vm.Searchtemporary()}}},[_vm._v(_vm._s(_vm.sedirector_appCount.dotsave)+" ")]),_vm._v(" คน ")],1)]),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"success"},on:{"click":function($event){return _vm.noticeApproveIpa()}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v("ประกาศผล ภาค ก. ")],1)],1)])])])],1)],1)],1)],1),_c('v-select',{attrs:{"items":_vm.headers,"label":"เลือกคอลัมน์ที่ต้องการแสดง","multiple":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                            var item = ref.item;
                            var index = ref.index;
return [(index < 6)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 6)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 6)+" คอลัมน์)")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.showHeaders,"items":_vm.sedirector_apps,"search":_vm.search,"item-key":"sedirector_app_idref","item-class":_vm.row_classes},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [(item.sedirector_app_id === _vm.editedItem.sedirector_app_id)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},[_vm._v(" mdi-window-close ")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" mdi-content-save ")])],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item.sedirector_app_idref)}}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item.sedirector_app_pvcscore_A_ipa",fn:function(ref){
                            var item = ref.item;
return [_c('div',[(item.sedirector_app_pvcscore_A_ipa === 'notice')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-cast")]):_c('v-icon',{attrs:{"color":"dark"}},[_vm._v("mdi-cast-off")])],1)]}},{key:"item.sedirector_app_pvcscore_A",fn:function(ref){
                            var item = ref.item;
return [(item.sedirector_app_pvcscore_A >= 60)?_c('v-chip',{attrs:{"color":"success","dark":""}},[_vm._v(" "+_vm._s(item.sedirector_app_pvcscore_A)+" ")]):(item.sedirector_app_pvcscore_A >= 1)?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s(item.sedirector_app_pvcscore_A)+" ")]):_c('v-chip',{attrs:{"color":"grey","dark":""}},[_vm._v(" - ")])]}},{key:"item.sedirector_app_pvcscore_A_file",fn:function(ref){
                            var item = ref.item;
return [(item.sedirector_app_pvcscore_A_file)?_c('div',[_c('v-btn',{attrs:{"fab":"","small":"","color":"success","dark":""},on:{"click":function($event){return _vm.viewsedirector_app_pvcscore_A_file(
                  item.sedirector_app_pvcscore_A_file
                )}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1):_c('div',[_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_vm._v(" ไม่ได้รายงานผล ")])],1)]}},{key:"item.sedirector_app_pvcscore_AB_CC",fn:function(ref){
                var item = ref.item;
return [(item.sedirector_app_pvcscore_AB_CC === 'ACC')?_c('div',[_c('v-btn',{attrs:{"rounded":"","color":"red","dark":""},on:{"click":function($event){return _vm.cancelpvcScoreA(item.sedirector_app_idref)}}},[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" ขอยกเลิกไฟล์")],1)],1):(item.sedirector_app_pvcscore_AB_CC === 'CF')?_c('div',[_c('v-chip',{attrs:{"color":"success","rounded":""}},[_vm._v("บันทึก")])],1):_c('div',[_c('v-chip',{attrs:{"dark":"","color":"warning"}},[_vm._v(" ไม่ได้รายงานผล ")])],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.sedirector_app_pvcscore_A_filedialog),callback:function ($$v) {_vm.sedirector_app_pvcscore_A_filedialog=$$v},expression:"sedirector_app_pvcscore_A_filedialog"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('embed',{attrs:{"src":'/HrvecRegisDir/' + _vm.pdffiles,"width":"100%","height":"700px"}})])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.snackbar.icon))]),_c('v-card-text',[_vm._v(_vm._s(_vm.snackbar.text))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }